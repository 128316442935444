import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const menuItems = [
  { name: 'Home', path: '/' },
  { name: 'Experience', path: '/experience' },
  { name: 'MyApps', path: '/myapps' },
];

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 bg-coba z-50 shadow-md">
        <div className="container mx-auto px-4 flex justify-between items-center h-24"> {/* Increased height */}
          <Link to="/" className="font-bold flex items-center h-full py-2">
            <img 
              src="/images/logo.png" 
              alt="Logo" 
              className="h-20 w-auto object-contain" // Increased fixed height
            />
          </Link>
          <div className="hidden md:block">
            <ul className="flex space-x-6">
              {menuItems.map((item) => (
                <li key={item.name}>
                  <Link to={item.path} className="text-white hover:text-blue-500 text-lg">{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <button className="md:hidden text-3xl text-white" onClick={toggleMenu}> {/* Increased icon size */}
            {isMenuOpen ? <X /> : <Menu />}
          </button>
        </div>
      </nav>
      {isMenuOpen && <MobileMenu toggleMenu={toggleMenu} />}
    </>
  );
};

const MobileMenu = ({ toggleMenu }) => (
  <div className="fixed inset-0 bg-light z-40 md:hidden pt-24"> {/* Adjusted top padding */}
    <div className="container mx-auto px-4">
      <ul className="flex flex-col items-center space-y-6 pt-4">
        {menuItems.map((item) => (
          <li key={item.name}>
            <Link 
              to={item.path} 
              className="text-xl hover:text-blue-500"
              onClick={toggleMenu}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Navigation;