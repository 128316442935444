import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import Layout from '../components/Layout';
import { ExternalLink, Globe, Star, Code2, ChevronLeft, ChevronRight } from 'lucide-react';

const MyApps = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const appsPerPage = 6;

  const generateApps = () => {
    const apps = [];
    for (let i = 1; i <= 200; i++) {
      apps.push({
        title: `Application ${i}`,
        description: `Deskripsi lengkap untuk aplikasi ${i}. Ini adalah penjelasan tentang fitur dan kegunaan aplikasi.`,
        technologies: ["React", "Node.js", `Tech ${i}`],
        imageUrl: "https://picsum.photos/600/400",
        demoUrl: `https://demo.example.com/app${i}`,
        liveUrl: `https://app${i}.example.com`,
        githubUrl: `https://github.com/yourusername/app${i}`,
        featured: i % 5 === 0
      });
    }
    return apps;
  };

  const apps = generateApps();
  const indexOfLastApp = currentPage * appsPerPage;
  const indexOfFirstApp = indexOfLastApp - appsPerPage;
  const currentApps = apps.slice(indexOfFirstApp, indexOfLastApp);
  const totalPages = Math.ceil(apps.length / appsPerPage);

  const paginate = (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <Helmet>
        <title>Hambalitubagus | Daftar Aplikasi yang Telah dibuat</title>
        <meta name="description" content="Temukan berbagai aplikasi yang telah hambalitubagus kembangkan, menggunakan teknologi modern dengan fokus pada pengalaman pengguna dan performa." />\
        <meta name="keywords" content="HambaliTubagus, pengalaman kerja, pendidikan, proyek, keterampilan, teknologi, portofolio" />
        <meta property="og:title" content="HambaliTubagus | Website Pengalaman saya dan Aplikasi Gratis yang bisa digunakan untuk Project Kamu" />
        <meta property="og:description" content="Temukan pengalaman kerja dan pendidikan HambaliTubagus. Lihat proyek yang telah dikerjakan dan keterampilan yang dimiliki." />
        <meta property="og:image" content="https://hambalitubagus.com/images/gunung.jpg" />
        <meta property="og:url" content="https://hambalitubagus.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="HambaliTubagus | Website Pengalaman saya dan Aplikasi Gratis yang bisa digunakan untuk Project Kamu" />
        <meta name="twitter:description" content="Jelajahi pengalaman kerja dan pendidikan HambaliTubagus, termasuk proyek-proyek yang telah dikerjakan, keterampilan yang dimiliki, dan pencapaian di dunia teknologi.." />
        <meta name="twitter:image" content="https://hambalitubagus.com/images/gunung.jpg" />
        <link rel="canonical" href="https://hambalitubagus.com/" />
  <meta name="robots" content="index, follow" />
      </Helmet>
      <section className="mb-12">
        <div className="text-center mb-12">
          <br />
          <h1 className="text-4xl font-bold text-primary mb-4">My Applications by Hambalitubagus</h1>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Kumpulan aplikasi yang telah saya kembangkan menggunakan berbagai teknologi modern.
            Setiap aplikasi dibuat dengan fokus pada pengalaman pengguna dan performa.
          </p>
          <div className="mt-4 text-gray-400">
            Menampilkan {indexOfFirstApp + 1} - {Math.min(indexOfLastApp, apps.length)} dari {apps.length} aplikasi
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {currentApps.map((app, index) => (
            <div 
              key={index}
              className="bg-gray-800/50 rounded-lg overflow-hidden shadow-lg"
            >
              <div className="relative">
                <img 
                  src={app.imageUrl} 
                  alt={app.title}
                  className="w-full h-48 object-cover"
                />
                {app.featured && (
                  <div className="absolute top-4 right-4 bg-yellow-500 text-black px-3 py-1 rounded-full flex items-center">
                    <Star className="w-4 h-4 mr-1" />
                    <span className="text-sm font-medium">Featured</span>
                  </div>
                )}
              </div>

              <div className="p-6">
                <h3 className="text-xl font-bold text-yellow-500 mb-2">{app.title}</h3>
                <p className="text-blue-400/80 text-sm mb-4">{app.description}</p>

                <div className="mb-6">
                  <div className="flex flex-wrap gap-2">
                    {app.technologies.map((tech, techIndex) => (
                      <span 
                        key={techIndex}
                        className="bg-blue-900/50 text-blue-400 px-3 py-1 rounded-md text-sm"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="flex items-center justify-between space-x-4">
                  <div className="flex space-x-2">
                    <a
                      href={app.demoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-3 py-1.5 rounded-md transition-colors text-sm"
                    >
                      <Globe className="w-4 h-4 mr-1" />
                      Try Demo
                    </a>
                    <a
                      href={app.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center bg-gray-700 hover:bg-gray-600 text-white px-3 py-1.5 rounded-md transition-colors text-sm"
                    >
                      <Code2 className="w-4 h-4 mr-1" />
                      Source
                    </a>
                  </div>
                  <a
                    href={app.liveUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-green-600 hover:bg-green-700 text-white px-3 py-1.5 rounded-md transition-colors text-sm"
                  >
                    <ExternalLink className="w-4 h-4 mr-1" />
                    Visit App
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center items-center gap-2">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 rounded-md bg-gray-800 hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Previous page"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          
          <div className="flex gap-2">
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              let pageNum;
              if (totalPages <= 5) {
                pageNum = i + 1;
              } else if (currentPage <= 3) {
                pageNum = i + 1;
              } else if (currentPage >= totalPages - 2) {
                pageNum = totalPages - 4 + i;
              } else {
                pageNum = currentPage - 2 + i;
              }
              
              return (
                <button
                  key={i}
                  onClick={() => paginate(pageNum)}
                  className={`px-4 py-2 rounded-md ${
                    currentPage === pageNum
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-800 hover:bg-gray-700'
                  }`}
                >
                  {pageNum}
                </button>
              );
            })}
            {totalPages > 5 && currentPage < totalPages - 2 && (
              <>
                <span className="px-4 py-2">...</span>
                <button
                  onClick={() => paginate(totalPages)}
                  className="px-4 py-2 rounded-md bg-gray-800 hover:bg-gray-700"
                >
                  {totalPages}
                </button>
              </>
            )}
          </div>

          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-2 rounded-md bg-gray-800 hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Next page"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </section>
    </Layout>
  );
};

export default MyApps;
