import React from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import BlogSection from '../components/BlogSection';
import FeaturesSection from '../components/FeaturesSection';
import ContactForm from '../components/ContactForm';

const HomePage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Hambalitubagus | Website Berbagai macam Aplikasi siap pakai yang dikembangkan oleh Hambali</title>
        <meta name="description" content="Jelajahi portofolio hambalitubagus, temukan aplikasi yang telah saya kembangkan, dan Pakai Souce Codenya untuk Keperluan mu." />
        <meta name="keywords" content="HambaliTubagus, pengalaman kerja, pendidikan, proyek, keterampilan, teknologi, portofolio" />
        <meta property="og:title" content="HambaliTubagus | Website Pengalaman saya dan Aplikasi Gratis yang bisa digunakan untuk Project Kamu" />
        <meta property="og:description" content="Temukan pengalaman kerja dan pendidikan HambaliTubagus. Lihat proyek yang telah dikerjakan dan keterampilan yang dimiliki." />
        <meta property="og:image" content="https://hambalitubagus.com/images/gunung.jpg" />
        <meta property="og:url" content="https://hambalitubagus.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="HambaliTubagus | Website Pengalaman saya dan Aplikasi Gratis yang bisa digunakan untuk Project Kamu" />
        <meta name="twitter:description" content="Jelajahi pengalaman kerja dan pendidikan HambaliTubagus, termasuk proyek-proyek yang telah dikerjakan, keterampilan yang dimiliki, dan pencapaian di dunia teknologi.." />
        <meta name="twitter:image" content="https://hambalitubagus.com/images/gunung.jpg" />
        <link rel="canonical" href="https://hambalitubagus.com/" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Hero />
      <BlogSection />
      <FeaturesSection />
      <ContactForm />
    </Layout>
  );
};

export default HomePage;
