// BlogSection.js
import React, { useState } from 'react';
import BlogCard from './BlogCard';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const blogPosts = [
  {
    id: 1,
    title: "Getting Started with React",
    content: "Learn the basics of React and start building your first app.",
    imageUrl: "https://via.placeholder.com/300x200?text=React+Basics"
  },
  {
    id: 2,
    title: "Advanced CSS Techniques",
    content: "Discover powerful CSS techniques to enhance your web designs.",
    imageUrl: "https://via.placeholder.com/300x200?text=CSS+Techniques"
  },
  {
    id: 3,
    title: "JavaScript ES6 Features",
    content: "Explore the new features introduced in JavaScript ES6.",
    imageUrl: "https://via.placeholder.com/300x200?text=JS+ES6"
  },
  {
    id: 4,
    title: "Building RESTful APIs",
    content: "Learn how to create robust RESTful APIs for your applications.",
    imageUrl: "https://via.placeholder.com/300x200?text=RESTful+APIs"
  },
  {
    id: 5,
    title: "Introduction to TypeScript",
    content: "Get started with TypeScript and improve your JavaScript projects.",
    imageUrl: "https://via.placeholder.com/300x200?text=TypeScript+Intro"
  },
  {
    id: 6,
    title: "Responsive Web Design",
    content: "Master the art of creating websites that work on any device.",
    imageUrl: "https://via.placeholder.com/300x200?text=Responsive+Design"
  },
  {
    id: 7,
    title: "Vue.js for Beginners",
    content: "Start your journey with Vue.js and build interactive UIs.",
    imageUrl: "https://via.placeholder.com/300x200?text=Vue.js+Basics"
  },
  {
    id: 8,
    title: "Python Data Analysis",
    content: "Learn how to analyze data effectively using Python.",
    imageUrl: "https://via.placeholder.com/300x200?text=Python+Analysis"
  },
  {
    id: 9,
    title: "Mobile App Development",
    content: "Explore different approaches to building mobile applications.",
    imageUrl: "https://via.placeholder.com/300x200?text=Mobile+Dev"
  }
];

const BlogSection = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 3;
  const totalPages = Math.ceil(blogPosts.length / cardsPerPage);

  const nextSlide = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const prevSlide = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  const visibleBlogs = blogPosts.slice(
    currentPage * cardsPerPage,
    (currentPage + 1) * cardsPerPage
  );

  return (
    <section className="mb-12 relative px-4 md:px-0">
      <h2 className="text-primary text-2xl font-bold mb-2">Daftar Aplikasi Yang Pernah Saya Buat hehe</h2>
      <p className="mb-6 text-white">Ayo Jelajahi Dan Coba Aplikasinya.</p>
      
      <div className="relative">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {visibleBlogs.map((blog) => (
            <BlogCard 
              key={blog.id}
              title={blog.title}
              content={blog.content}
              imageUrl={blog.imageUrl}
            />
          ))}
        </div>

        {/* Desktop Navigation Buttons */}
        <div className="hidden md:block">
          <button 
            onClick={prevSlide}
            className="absolute top-1/2 -left-12 transform -translate-y-1/2 bg-blue-500/50 hover:bg-blue-600/80 p-2 rounded-full shadow-md text-white backdrop-blur-sm"
          >
            <ChevronLeft size={24} />
          </button>
          <button 
            onClick={nextSlide}
            className="absolute top-1/2 -right-12 transform -translate-y-1/2 bg-blue-500/50 hover:bg-blue-600/80 p-2 rounded-full shadow-md text-white backdrop-blur-sm"
          >
            <ChevronRight size={24} />
          </button>
        </div>

        {/* Mobile Navigation Buttons */}
        <div className="flex md:hidden justify-center gap-4 mt-6">
          <button 
            onClick={prevSlide}
            className="bg-blue-500/50 hover:bg-blue-600/80 p-2 rounded-full shadow-md text-white backdrop-blur-sm"
          >
            <ChevronLeft size={24} />
          </button>
          <button 
            onClick={nextSlide}
            className="bg-blue-500/50 hover:bg-blue-600/80 p-2 rounded-full shadow-md text-white backdrop-blur-sm"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>

      <div className="border-b border-gray-200 my-12"></div>
    </section>
  );
};

export default BlogSection;