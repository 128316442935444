import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactForm.css';
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    const sanitizedFormData = {
      user_name: formData.name,
      user_email: formData.email,
      message: formData.message,
      'g-recaptcha-response': captchaValue,
    };

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, sanitizedFormData, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((response) => {
        setStatus('Pesan terkirim!');
        setFormData({ name: '', email: '', message: '' });
        setCaptchaValue(null);

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_NOREPLY_ID, {
          user_name: formData.name,
          user_email: formData.email,
        }, '8Xc0v7rkwdJgRjbwg')
          .then(() => {
            console.log('Auto-reply sent successfully!');
          })
          .catch((error) => {
            console.log('Failed to send auto-reply:', error);
          });
      })
      .catch((error) => {
        console.log(error);
        setStatus('Terjadi kesalahan saat mengirim pesan.');
      });
  };

  return (
    <section className="mb-12">
      <h2 className="text-2xl font-bold mb-6 text-primary">Contact Me</h2>
      <form onSubmit={handleSubmit}>
        <input
          className="w-full mb-4 p-2 border rounded"
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          className="w-full mb-4 p-2 border rounded"
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          className="w-full mb-4 p-2 border rounded"
          name="message"
          rows="4"
          placeholder="Text Here"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        
        <div className="recaptcha-container">
          <ReCAPTCHA
            sitekey="6LdHyHAqAAAAAInovsL6M4ha34ouj31wyc3hPIHW"
            onChange={(value) => setCaptchaValue(value)}
            ref={recaptchaRef}
          />
        </div>
        
        <button className="w-full bg-blue-500 text-white py-2 rounded" type="submit">
          Send
        </button>
      </form>
      {status && <p className="mt-4 text-red-500">{status}</p>}
    </section>
  );
};

export default ContactForm;