import React from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import Layout from '../components/Layout';

const Experience = () => {
  return (
    <Layout>
    <Helmet>
  <title>HambaliTubagus | Website Pengalaman saya dan Aplikasi Gratis yang bisa digunakan untuk Project Kamu </title>
  <meta name="description" content="Jelajahi pengalaman kerja dan pendidikan HambaliTubagus, termasuk proyek-proyek yang telah dikerjakan, keterampilan yang dimiliki, dan pencapaian di dunia teknologi." />
  <meta name="keywords" content="HambaliTubagus, pengalaman kerja, pendidikan, proyek, keterampilan, teknologi, portofolio" />
  <meta property="og:title" content="HambaliTubagus | Website Pengalaman saya dan Aplikasi Gratis yang bisa digunakan untuk Project Kamu" />
  <meta property="og:description" content="Temukan pengalaman kerja dan pendidikan HambaliTubagus. Lihat proyek yang telah dikerjakan dan keterampilan yang dimiliki." />
  <meta property="og:image" content="https://hambalitubagus.com/images/gunung.jpg" />
  <meta property="og:url" content="https://hambalitubagus.com" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="HambaliTubagus | Website Pengalaman saya dan Aplikasi Gratis yang bisa digunakan untuk Project Kamu" />
  <meta name="twitter:description" content="Jelajahi pengalaman kerja dan pendidikan HambaliTubagus, termasuk proyek-proyek yang telah dikerjakan, keterampilan yang dimiliki, dan pencapaian di dunia teknologi.." />
  <meta name="twitter:image" content="https://hambalitubagus.com/images/gunung.jpg" />
  <link rel="canonical" href="https://hambalitubagus.com/" />
  <meta name="robots" content="index, follow" />
</Helmet>

      <section className="mb-12">
        <h2 className="text-3xl font-bold text-primary text-center mb-8">Pengalaman dan Pendidikan</h2>
        
        {/* Pendidikan */}
        <div className="mb-10">
          <h3 className="text-2xl font-semibold mb-4 text-primary">Pendidikan</h3>
          <div className="border-l-4 border-blue-500 pl-4">
            <div className="mb-4">
              <h4 className="font-bold text-secondary">SDN 1 Kota</h4>
              <p className="text-gray-500 text-white">2004 - 2010</p>
              <p className="text-gray-700 text-white">Dasar-dasar pendidikan dan pengenalan dunia luar.</p>
            </div>
            <div className="mb-4">
              <h4 className="font-bold text-secondary">SMPN 2 Kota</h4>
              <p className="text-gray-500 text-white">2010 - 2013</p>
              <p className="text-gray-700 text-white">Mendalami berbagai mata pelajaran dan aktivitas ekstrakurikuler.</p>
            </div>
            <div className="mb-4">
              <h4 className="font-bold text-secondary">SMAN 3 Kota</h4>
              <p className="text-gray-500 text-white">2013 - 2016</p>
              <p className="text-gray-700 text-white">Fokus pada ilmu pengetahuan dan teknologi.</p>
            </div>
            <div className="mb-4">
              <h4 className="font-bold text-secondary">Universitas ABC</h4>
              <p className="text-gray-500 text-white">2016 - 2020</p>
              <p className="text-gray-700 text-white">Gel. Sarjana Teknik Informatika. Pengalaman dalam proyek-proyek teknologi.</p>
            </div>
          </div>
        </div>

        {/* Pengalaman Kerja */}
        <div>
          <h3 className="text-2xl font-semibold mb-4 text-primary">Pengalaman Kerja</h3>
          <div className="border-l-4 border-green-500 pl-4">
            <div className="mb-4">
              <h4 className="font-bold text-secondary">Digital Marketing Specialist</h4>
              <p className="text-gray-500 text-white">Perusahaan XYZ | 2020 - 2022</p>
              <p className="text-gray-700 text-white">Bertanggung jawab dalam pengelolaan kampanye digital dan analisis performa.</p>
            </div>
            <div className="mb-4">
              <h4 className="font-bold text-secondary">Web Developer</h4>
              <p className="text-gray-500 text-white">Perusahaan ABC | 2018 - 2020</p>
              <p className="text-gray-700 text-white">Mengembangkan dan memelihara aplikasi web yang responsif dan efisien.</p>
            </div>
            <div className="mb-4">
              <h4 className="font-bold text-secondary">Mobile App Developer</h4>
              <p className="text-gray-500 text-white">Perusahaan DEF | 2016 - 2018</p>
              <p className="text-gray-700 text-white">Berkontribusi dalam pengembangan aplikasi mobile untuk iOS dan Android.</p>
            </div>
          </div>
        </div>

        {/* Kemampuan */}
        <div className="mt-10">
          <h3 className="text-2xl font-semibold mb-4 text-primary">Kemampuan</h3>
          <ul className="list-disc pl-5 text-gray-700 text-white">
            <li>HTML, CSS, dan JavaScript</li>
            <li>React dan Redux</li>
            <li>Node.js dan Express</li>
            <li>Digital Marketing dan SEO</li>
            <li>Desain UI/UX</li>
            <li>Pengembangan Aplikasi Mobile (Android & iOS)</li>
            <li>Manajemen Proyek dan Tim</li>
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default Experience;
