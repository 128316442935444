import React from 'react';
import { Globe, Code2, ExternalLink } from 'lucide-react';

const BlogCard = ({ title, content, imageUrl, link }) => (
  <div className="bg-gray-800/50 rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
    <div className="relative">
      <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />
    </div>
    <div className="p-6">
      <h3 className="text-xl font-bold text-yellow-500 mb-2">{title}</h3>
      <p className="text-blue-400/80 text-sm mb-4">{content}</p>

      <div className="flex items-center justify-between space-x-4">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-3 py-1.5 rounded-md transition-colors text-sm"
        >
          <Globe className="w-4 h-4 mr-1" />
          Visit App
        </a>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center bg-gray-700 hover:bg-gray-600 text-white px-3 py-1.5 rounded-md transition-colors text-sm"
        >
          <Code2 className="w-4 h-4 mr-1" />
          Source Code
        </a>
      </div>
    </div>
  </div>
);

export default BlogCard;
