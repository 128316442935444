// FeaturesSection.js
import { Globe, Target, Smartphone } from 'lucide-react';
import FeatureCard from './FeatureCard';

const FeaturesSection = () => (
  <section className="mb-12">
    <h2 className="text-2xl text-primary font-bold text-center mb-8">
      Ini Adalah Keahlian aku yang aku pelajari saat aku pernah bekerja.
    </h2>
    <p className="text-center mb-12 text-white">
      Aku tidak Expert banget, tapi aku bisa dan tahu cara untuk mengimplementasikannya.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <FeatureCard
        icon={<Globe className="w-12 h-12 mx-auto mb-4 text-secondary" />}
        title="Digital Marketing"
        description="Menggunakan teknik digital untuk mempromosikan produk dan layanan."
        link="#digital-marketing"
      />
      <FeatureCard
        icon={<Target className="w-12 h-12 mx-auto mb-4 text-secondary" />}
        title="Web Application"
        description="Membangun aplikasi web yang responsif dan user-friendly."
        link="#web-application"
      />
      <FeatureCard
        icon={<Smartphone className="w-12 h-12 mx-auto mb-4 text-secondary" />}
        title="Mobile App Developer"
        description="Mengembangkan aplikasi mobile untuk platform Android dan iOS."
        link="#mobile-app"
      />
    </div>
    <br />
    <div className="border-b border-gray-200 my-19"></div>
  </section>
);

export default FeaturesSection;
