import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col bg-coba2 w-full overflow-x-hidden">
      <Navigation />
      <main className="flex-grow w-full px-4 pt-24">
        <div className="max-w-7xl mx-auto w-full">
          {children}
        </div>
      </main>
      <Footer />
      <div className="w-full text-center text-sm text-gray-500 py-4">
        © hambalitubagus. All rights reserved. | Privacy Policy | Terms of Service
      </div>
      <ScrollToTop /> 
    </div>
  );
};

export default Layout;
