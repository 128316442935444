import React from 'react';

const Hero = () => (
  <div className="py-12">
    <div className="flex flex-col md:flex-row items-center justify-between">
      <div className="w-full md:w-1/2 mb-8 md:mb-0">
        <h1 className="text-primary text-4xl font-bold mb-4">Halo, Selamat Datang Di website Saya ☺️ </h1>
        <p className="mb-7 text-white">
          Terimakasih, atas kedatanganmu untuk meninjau website ku.
          <p>aku merupakan seorang yang senang belajar dan terus belajar tanpa batas usia.</p>
          semua proyek aplikasi yang saya buat adalah untuk memenuhi kebutuhan saya sendiri dan 
          juga untuk membantu orang lain yang membutuhkan aplikasi tersebut. <br />

          <p>aku juga sering membuatkan aplikasi untuk tempat ku berkerja, saat ini aku sedang tidak berkerja. </p>
          aku tidak handal untuk mengoding dari 0, aku hanya mahir dalam penggunaan AI dalam membuat sebuah aplikasi, 
          sehingga saya bisa membuat aplikasi yang sangat cepat dan efisien serta fleksibelitas dalam penggunaan bahasa pemprogramman apapun.
          <p>seperti website yang kamu kunjungi saat ini itupun di bantu dengan AI.</p>
        </p>
        <div className="flex space-x-4">
          <a href="#" className="w-40 h-40 transition-transform hover:scale-110">
            <img 
              src="/images/kucing.webp" 
              alt="Cute Cat 1" 
              className="w-full h-full object-contain"
            />
          </a>
          <a href="#" className="w-40 h-40 transition-transform hover:scale-110">
            <img 
              src="/images/cool-fun.gif" 
              alt="Cute Cat 2" 
              className="w-full h-full object-contain"
            />
          </a>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex justify-center">
        <div className="w-full max-w-md overflow-hidden"> {/* Tambahkan max-w-md */}
          <img 
            src="/images/gunung.jpg" 
            alt="Gunung" 
            className="w-full h-auto object-cover" // Pastikan h-auto
          />
        </div>
      </div>
    </div>
    <div className="border-b border-gray-200 my-11"></div>
  </div>
);

export default Hero;
