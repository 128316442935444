// FeatureCard.js
const FeatureCard = ({ icon, title, description, link }) => (
  <div className="text-center border p-4 rounded-lg shadow-lg">
    {icon}
    <h3 className="font-bold mb-2 text-primary">{title}</h3>
    <p className="text-sm text-gray-200">{description}</p> {/* Warna teks disesuaikan */}
    <a href={link} className="text-blue-500 mt-4 inline-block" aria-label={`Read more about ${title}`}>
      Read more
    </a>
  </div>
);

export default FeatureCard;
