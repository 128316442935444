import React from 'react';
import { Instagram, Facebook, Twitter, Phone, Send } from 'lucide-react';

const Footer = () => (
  <footer className="py-8 border-t">
    <div className="max-w-4xl mx-auto px-4">
      <div className="flex flex-col md:flex-row justify-between items-start mb-8">
        <div className="mb-6 md:mb-0">
          <h2 className="text-2xl font-bold mb-4"><img 
              src="/images/logo.png" 
              alt="Logo" 
              className="h-20 w-auto object-contain" // Increased fixed height
            /></h2>
          <p className="text-sm mb-2 text-primary">Address:</p>
          <p className="text-sm text-white">bogor,city</p>
          <p className="text-sm mb-4 text-white">Jawa Barat</p>
          <div className="flex space-x-4">
            <a href="https://www.instagram.com/hambalitubagus/profilecard/?igsh=dWk5YmM4ODgzOWs0" className="text-pink-600 hover:text-pink-700">
              <Instagram size={24} />
            </a>
            <a href="https://www.facebook.com/tubagus.hambaliii" className="text-blue-600 hover:text-blue-700">
              <Facebook size={24} />
            </a>
            <a href="https://x.com/hambalitubagus" className="text-blue-400 hover:text-blue-500">
              <Twitter size={24} />
            </a>
            <a href="#" className="text-green-500 hover:text-green-600">
              <Phone size={24} />
            </a>
            <a href="#" className="text-blue-500 hover:text-blue-600">
              <Send size={24} />
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/2 h-48 mt-6 md:mt-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.147072094999!2d106.77376197674428!3d-6.628648193365678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69c580ac64fd9d%3A0x978b7c1ea2c85800!2sJl.%20Mandor%20Suki%20No.37%2C%20RT.3%2FRW.7%2C%20Kota%20Batu%2C%20Kec.%20Ciomas%2C%20Kabupaten%20Bogor%2C%20Jawa%20Barat%2016610%2C%20Indonesia!5e0!3m2!1sid!2skh!4v1730324747642!5m2!1sid!2skh"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;